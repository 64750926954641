<template>
    <div class="pages-body login-page flex flex-column">
        <div class="topbar p-3 flex justify-content-between flex-row align-items-center inari-bg">
            <router-link class="topbar-left ml-3 flex" to="/">
                <div class="logo">
                    <img src="layout/images/inari_logo_web.png" alt="ENAT INARI" />
                </div>
            </router-link>
            <router-link class="topbar-right mr-3 flex" to="/">
                <Button type="button" label="DASHBOARD" class="p-button-text p-button-plain topbar-button"></Button>
            </router-link>
        </div>

        <div class="align-self-center mt-auto mb-auto">
            <form @submit.prevent="login">
                <div class="pages-panel card flex flex-column">
                    <div class="pages-header px-3 py-1 bg-yellow">
                        <h2>LOGIN</h2>
                    </div>

                    <h4>ENAT INARI</h4>
                    <div class="pages-detail mb-6 px-6">{{ customerId }}</div>
                    <div class="pages-detail mb-6 px-6">Bitte Zugangsdaten eingeben</div>

                    <div class="input-panel flex flex-column px-3">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <!-- <span class="p-float-label"> -->
                                <InputText v-model="username" type="text" id="email" />
                                <!-- <label for="email">Benutzer</label> -->
                            <!-- </span> -->
                        </div>

                        <div class="p-inputgroup mt-3 mb-6">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-lock"></i>
                            </span>
                            <!-- <span class="p-float-label"> -->
                                <InputText v-model="password" type="password" id="password" @keydown.enter="login" />
                                <!-- <label for="password">Passwort</label> -->
                            <!-- </span> -->
                        </div>
                    </div>

                    <Button class="login-button mb-6 px-3" label="LOGIN" @click.prevent="login"></Button>
                    <div class="pages-detail mb-6 px-6 fg-red" v-if="showError"><strong>{{ errorTxt }}</strong></div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            username: '',
            password: '',
            errorTxt: '',
            showError: false,
        };
    },
    computed: {
        customerId: function () {
            if (this.$store.getters.getClientData.installation.customer.id !== null) {
                return this.$store.getters.getClientData.installation.customer.id;
            } else {
                return 'Systemanmeldung';
            }
        },
    },
    methods: {
        login() {
            let username = this.username;
            let password = this.password;
            this.$store
                .dispatch('auth/login', {
                    username,
                    password,
                })
                .then(() => {
                    // this.$router.push('/view/dashboard');
                    this.$router.push({ path: '/dashboard', replace: true });
                    this.errorTxt = '';
                    this.showError = false;
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.data.message === 'no such user found') {
                        this.errorTxt = 'Benutzer nicht gefunden!';
                    } else if (err.response.data.message === 'wrong authentication') {
                        this.errorTxt = 'Passwort falsch!';
                    } else {
                        this.errorTxt = 'Allgemeiner Fehler!';
                    }
                    this.showError = true;
                });
        },
    },
};
</script>

<style scoped>
    /* .login-page {
        background-size: auto !important;
    } */
    .logo img {
        height: 3rem !important;
    }
</style>
